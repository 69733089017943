import { MAX_CONTENT_WIDTH } from '../../../config';
import { isPhoneOrUndefined, isTablet } from '../device';

export const getContentWidth = (viewportWidth, hasDesktopStyling) => {
  if (hasDesktopStyling) {
    return viewportWidth;
  }

  return viewportWidth > MAX_CONTENT_WIDTH ? MAX_CONTENT_WIDTH : viewportWidth;
};

export const isWiderThanMaxContentWidth = width => width > MAX_CONTENT_WIDTH;

/**
 * Gets the absolute position of an element by recursively adding up the offsets of its parents.
 * @param el {object} A DOM element
 * @param acc {object} don't pass in. this is used as the accumulator to return.
 */
export const getPos = (el, acc = { x: 0, y: 0 }) =>
  el ? getPos(el.offsetParent, { x: acc.x + el.offsetLeft, y: acc.y + el.offsetTop }) : acc;

export const isExtraNarrowFormat = width => width < 360;
export const isNarrowFormat = width => width < 550;
export const isTabletOrSmaller = width => width <= 1000;
export const isWideFormat = width => width > 768;
export const isIPadOrLarger = width => width >= 768;
export const isLandscapeIpadOrLarger = width => width >= 1024;
export const isExtraWideFormat = width => width >= 1280;

// As of yet unused, just defining it for the sake of completeness
export const isMediumFormat = width => !isNarrowFormat(width) && !isWideFormat(width);

export const getDefaultViewport = () => {
  if (isPhoneOrUndefined()) {
    return { width: 375, height: 667 };
  }

  if (isTablet()) {
    return { width: 768, height: 1024 };
  }

  return { width: 1366, height: 768 };
};
