import Cookies from 'js-cookie';
import { useRef } from 'react';
import { useInterval } from 'beautiful-react-hooks';
import { AUTH_TOKEN_COOKIE_NAME } from '../../config';

/** Listen for login events from other tabs or windows */
export const useGlobalLoginEvent = (cb: () => void) => {
  const oldValue = useRef<string | undefined>(Cookies.get(AUTH_TOKEN_COOKIE_NAME));
  // Cookies don't generate events, so we must poll
  return useInterval(() => {
    const newValue = Cookies.get(AUTH_TOKEN_COOKIE_NAME);
    if (newValue && newValue !== oldValue.current) {
      oldValue.current = newValue;
      cb();
    }
  }, 500);
};

/** Listen for logout events from other tabs or windows */
export const useGlobalLogoutEvent = (cb: () => void) => {
  const oldValue = useRef<string | undefined>(Cookies.get(AUTH_TOKEN_COOKIE_NAME));
  // Cookies don't generate events, so we must poll
  useInterval(() => {
    const newValue = Cookies.get(AUTH_TOKEN_COOKIE_NAME);
    if (oldValue.current && !newValue) {
      oldValue.current = newValue;
      cb();
    }
  }, 500);
};
