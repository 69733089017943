import {
  LOGIN_LOGOUT,
  LOGIN_ACCOUNT_RECEIVED,
  PUBNUB_AUTH_RECEIVED,
  PASSWORD_RESET_CLEAR_ERRORS,
  PASSWORD_RESET_REQUESTED,
  LAST_COMPLETED_NPS_UPDATED,
  CLINIC_ACCOUNTS_LOCATION_PATCHED,
  CLINIC_ACCOUNTS_LOCATION_PATCH_ERROR,
} from '../constants';

const loginClearData = () => ({
  type: LOGIN_LOGOUT,
});

const accountReceived = (value) => ({
  type: LOGIN_ACCOUNT_RECEIVED,
  payload: { value },
});

const pubnubAuthReceived = (locationId) => (value) => ({
  type: PUBNUB_AUTH_RECEIVED,
  payload: { locationId, value },
});

const passwordResetRequested = () => ({
  type: PASSWORD_RESET_REQUESTED,
});

const passwordResetClearErrors = () => ({
  type: PASSWORD_RESET_CLEAR_ERRORS,
});

const lastCompletedNpsUpdated = (value) => ({
  type: LAST_COMPLETED_NPS_UPDATED,
  payload: { value },
});

const updateClinicAccountsLocationSuccess = (value) => ({
  type: CLINIC_ACCOUNTS_LOCATION_PATCHED,
  payload: { value },
});

const updateClinicAccountsLocationError = (value) => ({
  type: CLINIC_ACCOUNTS_LOCATION_PATCH_ERROR,
  payload: { value },
});

export {
  loginClearData,
  accountReceived,
  pubnubAuthReceived,
  passwordResetRequested,
  passwordResetClearErrors,
  lastCompletedNpsUpdated,
  updateClinicAccountsLocationSuccess,
  updateClinicAccountsLocationError,
};
