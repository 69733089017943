import { WAIT_TIME_RECEIVED, WAIT_TIME_ERROR, LOADING_WAIT_TIME } from '../constants';

const receiveWaitTime = value => ({
  type: WAIT_TIME_RECEIVED,
  payload: { value },
});

const waitTimeError = value => ({
  type: WAIT_TIME_ERROR,
  payload: { value },
});

const loadingWaitTime = () => ({
  type: LOADING_WAIT_TIME,
});

export { receiveWaitTime, waitTimeError, loadingWaitTime };
