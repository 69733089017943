import { useEffect } from 'react';
import { getAuth, removeAuth } from '../../core/auth';
import { clearPersistedStore } from '../../store/configureStore';
import { postRevoke } from '../../core/dapi/login';

export default function LogOut() {
  useEffect(() => {
    (async () => {
      const access_token = getAuth()?.access_token;

      // Clears the persisted redux store and removes the login cookie
      clearPersistedStore();
      removeAuth();

      if (access_token) {
        await postRevoke(access_token);
      }

      // Redirect after the async function is complete
      window.location.replace('/login');
    })();
  }, []);

  return <></>;
}
