import { isClientSide } from './system';
import { hasAccessToNonTelemedLocation } from '../session';
import { MAPP_BASE_URL } from '../../constants/url';
import { GenericObject } from './generics';

const getCurrentPath = () => {
  if (isClientSide()) {
    return decodeURIComponent(window.location.pathname.replace(/\+/g, ' '));
  }

  return null;
};

const getQueryParams = (url = window.location.href): GenericObject => {
  if (!url) {
    return {};
  }

  return (/\?/.test(url) ? url.slice(url.indexOf('?') + 1) : url).split('&').reduce(
    (params, param) => {
      const newParams: GenericObject = { ...params };
      const keyValuePair = param.split('=');
      if (keyValuePair.length > 0) {
        const key = keyValuePair[0];
        const value = keyValuePair[1];
        newParams[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
      }

      return newParams;
    },

    {}
  );
};

const getQueueUrlPath = (locationIds: string[]) => {
  const path = '/queue';
  if (locationIds.length === 0) return path;
  return `${path}/?location_ids=${locationIds.join(',')}`;
};

const getTelemedOnlyReportsRedirect = (login: any, key: string) =>
  hasAccessToNonTelemedLocation(login) ? `/${key}` : `/${key}/telemed`;

const getMobileCheckinRedirect = (bookingId: string) =>
  `${MAPP_BASE_URL}/mobile-check-in/${bookingId}/success`;

export {
  getCurrentPath,
  getQueryParams,
  getQueueUrlPath,
  getTelemedOnlyReportsRedirect,
  getMobileCheckinRedirect,
};
