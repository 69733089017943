import Bowser from 'bowser';

export const isInternetExplorer = () => {
  if (typeof window !== 'undefined') {
    const browser = Bowser.detect(window.navigator.userAgent);
    return browser.name === 'Internet Explorer';
  }

  return false;
};
