import { Account, AccountLogin, LoginToken } from '../types/RootState';
import { ACCOUNT_ROLE_SOLV_EMPLOYEE } from '../constants';
import { coreAnalyticsTrackEvent } from '../core/analytics/coreAnalyticsTrackEvent';
import { QUEUE_TAP_LOGIN } from '../core/analytics/events';
import { getPubnubClinicAccountAuthUrl } from '../core/dapi/pubnub';
import { accountReceived, pubnubAuthReceived } from '../actions/login';
import { setAuth } from '../core/auth';
import { getClinicAccountUrl } from '../core/dapi/clinicAccounts';
import { LocationFormatters } from '../core/data/mappers';
import { HttpError, httpGetJson } from '../core/http/http';
import { DapiSingleResult } from '../core/dapi/response';
import moment from 'moment-timezone';
import { isItAdmin, isSolvEmployee } from '../core/session';
import { clearPersistedStore } from '../store/configureStore';
import { log } from '../core/logger/log';
import { getClinicAccountPermissions } from '../core/util/clinicAccounts';
import { getTrackingProperties } from '../core/analytics/properties';
import { analyticsIdentify } from '../core/analytics';

export type AfterLoginOpts = {
  redirectUrl?: string;
  clearRemixCache?: boolean;
};

export async function onLoginSuccess(dispatch: any, token: LoginToken, opts?: AfterLoginOpts) {
  console.debug('on login success', token.meta?.last_refresh);
  // Clear the redux store so we can assure its a fresh state upon login
  clearPersistedStore();

  await setAuth(token, {
    clearRemixCache: opts?.clearRemixCache ?? false,
  });

  const { data: account } = await httpGetJson<DapiSingleResult<Account>>(
    getClinicAccountUrl(token.clinic_account_id)
  );

  account.locations = LocationFormatters.formatAccountLocations(account.locations);
  dispatch(accountReceived(account));

  const login: AccountLogin = {
    account,
    pubNub: {},
  };

  /*
    This isn't critically important if it fails to load, the app will still be use-able without it
    so catch it just in case, so we aren't preventing them from using manage if it doesn't work
   */
  try {
    const { data: pubNub } = await httpGetJson<
      DapiSingleResult<{ auth_key: string; subscribe_key: string }>
    >(getPubnubClinicAccountAuthUrl(token.clinic_account_id));
    const pubNubRoom = `clinic_account_id:${token.clinic_account_id}`;
    dispatch(pubnubAuthReceived(pubNubRoom)(pubNub));
    login.pubNub = {
      [pubNubRoom]: pubNub,
    };
  } catch (e) {
    log.error(e);
  }

  if (account.locations.length === 0 && !isSolvEmployee(login) && !isItAdmin(login)) {
    throw new HttpError(
      {
        url: '',
        status: 400,
      },
      'Oops! Your account does not have any locations assigned. Please contact your ' +
        'account administrator.'
    );
  }

  const trackingProperties = getTrackingProperties({ login });

  coreAnalyticsTrackEvent(QUEUE_TAP_LOGIN, trackingProperties);

  const cleanedCurrentPath = window.location.pathname.replace('/', '').trim();

  /*
  Important that we do a full navigation instead of router push,
  that way redux re-initializes after we clear the store
   */
  if (account.requires_password_reset && cleanedCurrentPath !== 'reset-password') {
    window.location.replace('/reset-password');
    return;
  }

  const passwordExpirationDate = account.password_expiration_date;
  if (moment().isAfter(passwordExpirationDate) && cleanedCurrentPath !== 'expired-password') {
    window.location.replace('/expired-password');
    return;
  }

  analyticsIdentify(account.id);

  const redirectUrl = opts?.redirectUrl || '/hello';

  const cleanedRedirectUrl = redirectUrl.replace('/', '').split('?')[0].trim();

  console.debug('onLoginSuccess redirect to', redirectUrl, cleanedRedirectUrl, cleanedCurrentPath);

  if (cleanedRedirectUrl === cleanedCurrentPath) {
    console.debug('already on the same url we are redirecting to.');
    return;
  }

  if (redirectUrl.startsWith('/')) {
    window.location.replace(redirectUrl);
    return;
  }

  window.location.replace(`/${redirectUrl}${window.location.search}`);
}
