export async function retryAsync<T>(callback: () => Promise<any>, attempts: number): Promise<T> {
  let error: any | undefined;
  for (let i = 0; i < attempts; i++) {
    try {
      return await callback();
    } catch (ex) {
      error = ex;
    }
  }
  throw error;
}
