import { DAPI_HOST, DAPI_CLIENT_ID, DAPI_CLIENT_SECRET } from '../../config';
import { apiPostJson } from './index';

const getLoginUrl = () => `${DAPI_HOST}/v1/auth/token`;

const getRevokeUrl = () => `${DAPI_HOST}/v1/auth/revoke`;

const postRevoke = async (access_token) => {
  const postData = {
    client_id: DAPI_CLIENT_ID,
    client_secret: DAPI_CLIENT_SECRET,
    access_token: access_token,
  };

  const onFailure = (response) => {
    console.error('Failed to revoke access token', JSON.stringify(response));
  };

  await apiPostJson(getRevokeUrl(), postData).catch(onFailure);
};

export { getLoginUrl, postRevoke };
