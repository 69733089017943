import { connect } from 'react-redux';
import { getWaitTime } from '../../dispatchers/waitTime';
import { apiPostJson } from '../../core/dapi';
import { getClearDemoDataUrl, getResetDemoDataUrl } from '../../core/dapi/demoData';
import TopNav from './TopNav';

/**
 * @param {import('../../types/RootState').RootState} state
 */
const mapStateToProps = (state) => ({
  login: state.login,
  selectedLocationIds: state.queue.selectedLocationIds,
});

const mapDispatchToProps = (dispatch) => ({
  getWaitTime: getWaitTime(dispatch),

  clearDemoData: (locationId) => apiPostJson(getClearDemoDataUrl(locationId), {}),

  resetDemoData: (locationId) => apiPostJson(getResetDemoDataUrl(locationId), {}),
});

export default connect(mapStateToProps, mapDispatchToProps)(TopNav);
