import { useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from '../../types/Redux';
import { useIdleTimer } from 'react-idle-timer';
import { analyticsTrackEvent } from '../../core/analytics';
import { useInterval } from 'beautiful-react-hooks';
import moment from 'moment';
import { MANAGE_UPDATE } from '../../core/analytics/events';
import { isProd } from '../../config';
import { getBooleanFlag } from '../../core/flags/flags';
import { RootState } from '../../types/RootState';

const cantForceModes: Array<RootState['newVersion']['mode']> = ['telemed', 'facesheet'];

export function useNewVersionCheck({ onUpdate }: { onUpdate: () => void }): {
  canUpdate: boolean;
} {
  const [canUpdate, setCanUpdate] = useState(false);
  const [isIdle, setIdle] = useState(false);
  const ignoring = useRef(false);

  const updateAvailableShowAlertTime = useSelector(
    (w) => w.newVersion.updateAvailableShowAlertTime
  );

  const mode = useSelector((w) => w.newVersion.mode);

  useIdleTimer({
    timeout: !isProd() ? 10 * 1000 : 1000 * 60 * 3,
    debounce: 500,
    onActive: () => {
      if (isIdle) {
        setIdle(false);
      }
    },
    onIdle: () => {
      if (!isIdle) {
        setIdle(true);
      }
    },
  });

  const doUpdate = useCallback(
    ({ idle }: { idle: boolean }) => {
      analyticsTrackEvent(MANAGE_UPDATE, {
        showTime: updateAvailableShowAlertTime,
        idle,
      });
      onUpdate();
    },
    [onUpdate, updateAvailableShowAlertTime]
  );

  useEffect(() => {
    if (!canUpdate) {
      return;
    }

    const alwaysForce = getBooleanFlag('manage-force-update-version', false);

    console.debug('update available', 'current mode', mode, 'forcing update', alwaysForce);

    if (!alwaysForce) {
      return;
    }

    // We don't want to force refresh on this as it would interrupt a call or cause unsaved work
    if (cantForceModes.includes(mode)) {
      return;
    }
    /**
     * This will be toggled on if we are forcing an update in the event of an emergency, so we do
     * not care if the user is idle or not. We want to refresh now.
     *
     * The only exceptions are, do not refresh if they are in a telemed call or have the facesheet open
     */
    doUpdate({ idle: false });
  }, [canUpdate, doUpdate, mode]);

  const doUpdateOnIdle = useCallback(() => {
    if (!canUpdate || !isIdle) {
      return;
    }

    if (cantForceModes.includes(mode)) {
      return;
    }

    // If we are on the kiosk and idle, just force the update.
    if (mode === 'kiosk') {
      return doUpdate({ idle: true });
    }

    const currentHour = new Date().getHours();
    // If it is between 1 - 4 AM, we should just force refresh to update older versions that are just left on.
    if (currentHour >= 1 && currentHour <= 4) {
      return doUpdate({ idle: true });
    }
  }, [canUpdate, doUpdate, isIdle, mode]);

  useEffect(() => {
    if (canUpdate && isIdle) {
      doUpdateOnIdle();
    }
  }, [canUpdate, doUpdateOnIdle, isIdle]);

  useInterval(() => {
    // Return here so we aren't checking for an update when its processing the ignore.
    if (ignoring.current) {
      return;
    }

    const isUpdateAvailable = updateAvailableShowAlertTime
      ? moment(updateAvailableShowAlertTime).isBefore(moment())
      : false;

    if (canUpdate !== isUpdateAvailable) {
      setCanUpdate(isUpdateAvailable);
    }
  }, 5000);

  return {
    canUpdate,
  };
}
